@import "../../../index.scss";
@import "../Input.module.scss";

$sizes: (
    s: (
        height: 24px,
        padding: 4px 8px,
    ),
    sm: (
        height: 32px,
        padding: 8px,
    ),
);

.container {
    @include input;

    @each $size, $size-style in $sizes {
        .select-#{$size} {
            height: map-get($map: $size-style, $key: height);
            align-self: stretch;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            padding: map-get($map: $size-style, $key: padding);
            border-radius: 4px;
            box-shadow: inset 1px 2px 2px 0 #0000001e;
            border: solid 1px var(--neutral-50);
            background-color: var(--neutral-10);
            appearance: none;

            background: transparent;
            background-image: url("../../../assets/dropdown-arrow-icon.svg");
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: center;

            color: $neutral-70;

            @if $size == "sm" {
                @include Text-MRegular-14-on-16;
            } @else {
                @include Text-SRegular;
            }
        }
    }
}
