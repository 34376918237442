@import "../../index.scss";

@mixin input {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 4px;
    padding: 0;
    min-width: max-content;

    .helper {
        height: 16px;
        align-self: stretch;
        flex-grow: 0;
        text-align: left;
        color: $neutral-60;
        @include Text-SRegular;
    }

    label {
        @include Text-MRegular;

        height: 20px;
        align-self: stretch;
        flex-grow: 0;
        text-align: left;
        color: $neutral-100;

        &.disabled {
            color: $neutral-70;
        }
    }
}
