.bread-crumb {
    display: flex;
    align-items: center;
    gap: 8px;

    .crumb {
        display: flex;
        flex-direction: row;
        gap: inherit;

        flex-grow: 0;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;

        .text {
            &:not(:last-child) {
                color: var(--primary-default);
                cursor: pointer;
            }

            &:not(:last-child):hover {
                color: var(--primary-hover);
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .divider {
            padding: 0 8px;
            font-family: Inter;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-decoration: none;
            pointer-events: none;
            user-select: none;
            color: var(--neutral-70);
        }
    }
}
