@import "../../index.scss";

.content {
  height: 100%;
  padding: 16px;
  overflow-y: auto;
}

.formField {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}

.spacer {
  width: 16px;
}

// Report Styles
.reportContainer {
  font-family: 'Arial, sans-serif';
  color: #333;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 20px;
  
  h2 {
    font-size: 1.8em;
    color: #4a90e2;
    margin-bottom: 10px;
  }
}

.section {
  margin-bottom: 20px;

  h3 {
    font-size: 1.4em;
    color: #333;
    border-bottom: 2px solid #4a90e2;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      background-color: #fff;
      padding: 10px;
      margin-bottom: 5px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  p {
    margin: 0;
    padding: 5px 0;
    font-size: 1em;
  }
}

.totalHours {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  background-color: #eaf4fc;
  padding: 10px;
  border-radius: 6px;
}
