@import "../../../index.scss";

.content {
  padding: 24px;
  height: 100%;
  h2 {
    margin-bottom: 24px;
    font-size: 1.8rem;
    color: #333;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  label {
    font-size: 1rem;
    font-weight: bold;
    color: #555;
  }

  input[type="text"],
  input[type="time"] {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.2s;
  }

  input[type="text"]:focus,
  input[type="time"]:focus {
    border-color: var(--primary-border);
    outline: none;
  }

  .timeSpansContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .timeSpan {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
  }

  .addTimeSpan {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding-top: 8px;
    border-top: 1px solid #ddd;
  }

  .daysContainer {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .daysContainer label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.9rem;
  }

  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  @media (max-width: 600px) {
    .timeSpan {
      flex-direction: column;
    }
  }
}

