@import "../../index.scss";

.content {
  height: 100%;
  padding: 16px;
  overflow-y: hidden;
  display: flex;
  gap: 16px;

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      margin-left: 16px;
    }
  }
}

.recentAlerts,
.compHist {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  gap: 16px;
}

.compSelect {
  display: flex;
  flex-direction: column;
  gap: 16px;
}