@import "../../index.scss";

.content {
  height: 100%;
  padding: 16px;
  overflow-y: auto;
}

.scheduleDetails {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 6px;
  width: 50%;
  min-width: 230px;
  background-color: rgba(230, 234, 238, 0.75);

  span {
    color: #595959;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    margin: 4px 0 4px 0;
    padding-left: 2px;
  }
  span:first-of-type {
    color: #000;
    margin-bottom: 6px;
    padding-left: 0;
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
  }
  dt {
    flex-basis: 30%;
    padding: 4px;
    //background: azure;
    border-top: 1px solid #d9d9d9;
    text-align: right;
    color: #595959;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
  }
  dd {
    flex-basis: 60%;
    flex-grow: 1;
    margin: 0;
    padding: 4px;
    display: flex;
    align-content: center;
    /*    border-bottom: 1px solid #333;*/
    border-top: 1px solid #d9d9d9;

    font-size: 13px;
    color: #000;
    font-weight: normal;
    line-height: 1.54;
  }

  dd:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dd:nth-of-type(even) {
    background-color: #fff;
  }

  dt:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dt:nth-of-type(even) {
    background-color: #fff;
  }
}
