@import "../../index.scss";

.tabs {
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
}

.tabs button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
}

.tabs button:hover {
    color: #6AAD00;
}

.tab-headers {
    display: flex;
    border-bottom: 1px solid #ccc;
}
  
.tab-button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
}
  
.tab-button.active {
    border-bottom: 2px solid #007bff;
    color: #007bff;
    font-weight: bold;
}
  
.tab-content {
    padding: 12px 12px;
    font-size: 16px;
}

.active {
    background-color: #ccc;
    color: #6AAD00;
}
  
.text {
    font-size: 14px;
    font-weight: 600;
}