.content {
    height: 100%;
    padding: 16px;
    overflow-y: auto;
}

.actions {
    display: flex; 
    justify-content: flex-end; 
    gap: 10px;
}

label {
    font-family: Inter;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.08px;
    height: 20px;
    align-self: stretch;
    flex-grow: 0;
    text-align: left;
    color: #0a0a0a;
}

.headerCell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.selectedDay {
    background-color: #dff8ce;
}