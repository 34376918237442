/* -------------------------------------------------------------------------- */
/*                                Global Styles                               */
/* -------------------------------------------------------------------------- */
body {
    margin: 0;
    font-family: Inter;
    display: flex;
    flex-direction: column;

    height: 100vh;
    overflow: hidden;
}

.page-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 85%;
}

@mixin page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: stretch;
    margin: 24px;
    background-color: #fff;
}

.app-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 100vh;
}

.navigation-container {
    width: 240px;
    flex-shrink: 0;
}

/* -------------------------------------------------------------------------- */
/*                                   Spacing                                  */
/* -------------------------------------------------------------------------- */
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

/* -------------------------------------------------------------------------- */
/*                                   Colors                                   */
/* -------------------------------------------------------------------------- */
$neutral-10: #fff;
$variant-border: #7b61ff;
$neutral-60: #9e9e9e;
$neutral-20: #f5f5f5;
$neutral-80: #616161;
$neutral-70: #757575;
$neutral-30: #ededed;
$neutral-40: #e0e0e0;
$neutral-90: #424242;
$neutral-50: #c2c2c2;
$neutral-100: #0a0a0a;
$neutral-10-at-80: rgba(255, 255, 255, 0.8);
$neutral-90-at-30: rgba(66, 66, 66, 0.3);
$neutral-10-at-94: rgba(255, 255, 255, 0.94);
$neutral-10-at-50: rgba(255, 255, 255, 0.5);
$neutral-80-at-60: rgba(97, 97, 97, 0.6);
$neutral-70-at-16: rgba(117, 117, 117, 0.16);
$neutral-100-at-4: rgba(0, 0, 0, 0.04);
$neutral-transparent: rgba(255, 255, 255, 0);
$primary-default: #6aad00;
$primary-hover: #66a00b;
$primary-focus: rgba(136, 200, 35, 0.2);
$primary-active: #57870b;
$primary-light: #9ddf46;
$primary-dark: #367d00;
$primary-border: #bce778;
$primary-very-light: #c9e7a1;
$primary-visited: #362ac0;
$primary-surface: #fafff2;
$secondary-default: #6c757d;
$secondary-light: #9aa4ac;
$secondary-border: #cbd1d7;
$secondary-surface: #f0f4f8;
$secondary-hover: #535a61;
$secondary-active: #414951;
$secondary-dark: #414951;
$danger-default: #e00000;
$danger-surface: #ffecec;
$danger-border: #ffd7d7;
$secondary-focus: rgba(108, 117, 125, 0.2);
$danger-hover: #a20000;
$danger-active: #630000;
$danger-focus: rgba(224, 0, 0, 0.2);
$warning-surface: #fff2e7;
$warning-border: #ffca99;
$warning-hover: #d16603;
$warning-active: #653508;
$success-default: #008243;
$success-surface: #ebf5f0;
$success-border: #b3dac7;
$success-hover: #006836;
$success-active: #005029;
$success-focus: rgba(0, 130, 67, 0.2);
$info-default: #0b5cd7;
$info-surface: #ecf2fc;
$info-border: #b6cff3;
$info-hover: #044dba;
$info-active: #043c8f;
$table-light-edit-row-background-row-hover: #fff;
$table-light-normal-cell-background-active: rgba(26, 122, 187, 0.12);
$table-light-normal-cell-background-hover: rgba(26, 122, 187, 0.06);
$table-dark-normal-cell-background-active: rgba(26, 122, 187, 0.1);
$systemic-logo-green-text: #44b549;
$state-colours-info: #5a90e2;
$state-colours-low: #fdd64d;
$state-colours-warning: #ff9c54;
$state-colours-high: #ff601c;
$warning-default: #ea780e;
$state-colours-severe: #eb294c;
$state-colours-critical: #e01a91;
$state-colours-ok: #58ca93;
$notifications-badge-red: #eb4d3d;
$i-os-keyboard-icons: #50555c;
$i-os-keyboard-background: #d1d3d9;
$i-os-keyboard-dark-keys-background: #abb0bc;
$i-os-light-mode-sheet-background: #f2f2f6;
$i-os-light-mode-panel-background: rgba(245, 245, 245, 0.94);
$i-os-light-mode-perentie-panel-background: rgba(246, 252, 239, 0.94);
$i-os-system-colours-green: #34c759;
$i-os-system-colours-blue: #007aff;
$main-menu-dark-background: #242728;
$main-menu-dark-item-background: #242728;
$main-menu-dark-item-hover-background: #383d3f;
$main-menu-dark-item-focused-border: rgba(87, 101, 105, 0.2);
$main-menu-dark-item-active-background: #354145;
$main-menu-dark-header-background: #191b1c;
$top-bar-dark-background: #0c3357;
$perentie-logo-head: #fdfdfd;
$perentie-logo-text: #b6d44a;
$perentie-logo-dark-green: #91b349;
$neutral-10: #fff;
$variant-border: #7b61ff;
$neutral-60: #9e9e9e;
$neutral-20: #f5f5f5;
$neutral-80: #616161;
$neutral-70: #757575;
$neutral-30: #ededed;
$neutral-40: #e0e0e0;
$neutral-90: #424242;
$neutral-50: #c2c2c2;
$neutral-100: #0a0a0a;
$neutral-10-at-80: rgba(255, 255, 255, 0.8);
$neutral-90-at-30: rgba(66, 66, 66, 0.3);
$neutral-10-at-94: rgba(255, 255, 255, 0.94);
$neutral-10-at-50: rgba(255, 255, 255, 0.5);
$neutral-80-at-60: rgba(97, 97, 97, 0.6);
$neutral-70-at-16: rgba(117, 117, 117, 0.16);
$neutral-100-at-4: rgba(0, 0, 0, 0.04);
$neutral-transparent: rgba(255, 255, 255, 0);
$primary-default: #6aad00;
$primary-hover: #66a00b;
$primary-focus: rgba(136, 200, 35, 0.2);
$primary-active: #57870b;
$primary-light: #9ddf46;
$primary-dark: #367d00;
$primary-border: #bce778;
$primary-very-light: #c9e7a1;
$primary-visited: #362ac0;
$primary-surface: #fafff2;
$secondary-default: #6c757d;
$secondary-light: #9aa4ac;
$secondary-border: #cbd1d7;
$secondary-surface: #f0f4f8;
$secondary-hover: #535a61;
$secondary-active: #414951;
$secondary-dark: #414951;
$danger-default: #e00000;
$danger-surface: #ffecec;
$danger-border: #ffd7d7;
$secondary-focus: rgba(108, 117, 125, 0.2);
$danger-hover: #a20000;
$danger-active: #630000;
$danger-focus: rgba(224, 0, 0, 0.2);
$warning-surface: #fff2e7;
$warning-border: #ffca99;
$warning-hover: #d16603;
$warning-active: #653508;
$success-default: #008243;
$success-surface: #ebf5f0;
$success-border: #b3dac7;
$success-hover: #006836;
$success-active: #005029;
$success-focus: rgba(0, 130, 67, 0.2);
$info-default: #0b5cd7;
$info-surface: #ecf2fc;
$info-border: #b6cff3;
$info-hover: #044dba;
$info-active: #043c8f;
$table-light-edit-row-background-row-hover: #fff;
$table-light-normal-cell-background-active: rgba(26, 122, 187, 0.12);
$table-light-normal-cell-background-hover: rgba(26, 122, 187, 0.06);
$table-dark-normal-cell-background-active: rgba(26, 122, 187, 0.1);
$systemic-logo-green-text: #44b549;
$state-colours-info: #5a90e2;
$state-colours-low: #fdd64d;
$state-colours-warning: #ff9c54;
$state-colours-high: #ff601c;
$warning-default: #ea780e;
$state-colours-severe: #eb294c;
$state-colours-critical: #e01a91;
$state-colours-ok: #58ca93;
$notifications-badge-red: #eb4d3d;
$i-os-keyboard-icons: #50555c;
$i-os-keyboard-background: #d1d3d9;
$i-os-keyboard-dark-keys-background: #abb0bc;
$i-os-light-mode-sheet-background: #f2f2f6;
$i-os-light-mode-panel-background: rgba(245, 245, 245, 0.94);
$i-os-light-mode-perentie-panel-background: rgba(246, 252, 239, 0.94);
$i-os-system-colours-green: #34c759;
$i-os-system-colours-blue: #007aff;
$main-menu-dark-background: #242728;
$main-menu-dark-item-background: #242728;
$main-menu-dark-item-hover-background: #383d3f;
$main-menu-dark-item-focused-border: rgba(87, 101, 105, 0.2);
$main-menu-dark-item-active-background: #354145;
$main-menu-dark-header-background: #191b1c;
$top-bar-dark-background: #0c3357;
$perentie-logo-head: #fdfdfd;
$perentie-logo-text: #b6d44a;
$perentie-logo-dark-green: #91b349;

/* -------------------------------------------------------------------------- */
/*                                    Fonts                                   */
/* -------------------------------------------------------------------------- */
@mixin HeadingL {
    font-family: Inter;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.22;
    letter-spacing: -0.79px;
}
@mixin HeadingM {
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: -0.59px;
}
@mixin SF-Symbols28-on-28 {
    font-family: SFPro;
    font-size: 28px;
    line-height: 1;
}
@mixin SF-Symbols27-on-27 {
    font-family: SFPro;
    font-size: 27px;
    line-height: 1;
    letter-spacing: -0.3px;
}
@mixin SF-Symbols23-on-24 {
    font-family: SFProText;
    font-size: 23px;
    line-height: 1.04;
}
@mixin SF-Symbols22-on-22 {
    font-family: SFPro;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
}
@mixin iOS-KeyboardUppercase-Letter {
    font-family: Inter;
    font-size: 22px;
    line-height: 1.27;
    letter-spacing: 0.35px;
}
@mixin HeadingS {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.34px;
}
@mixin SF-Symbols17-on-17 {
    font-family: SFPro;
    font-size: 17px;
    font-weight: 500;
    line-height: 1;
}
@mixin SF-Symbols17-on-20 {
    font-family: SFPro;
    font-size: 17px;
    line-height: 1.18;
}
@mixin Text-LSemi-Bold {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.18px;
}
@mixin Text-LMedium {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.18px;
}
@mixin SF-Symbols16-on-24 {
    font-family: SFPro;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}
@mixin iOS-KeyboardBottom-Row-Keys {
    font-family: Inter;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: -0.32px;
}
@mixin Text-LRegular {
    font-family: Inter;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.18px;
}
@mixin SF-Symbols16-on-16 {
    font-family: SFPro;
    font-size: 16px;
    line-height: 1;
}
@mixin Text-MMedium-14-on-16 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
@mixin Text-MMedium {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.08px;
}
@mixin Text-Code-MRegular {
    font-family: Inconsolata;
    font-size: 14px;
    line-height: 1.14;
}
@mixin Text-MRegular-Italic {
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    line-height: 1.43;
    letter-spacing: -0.08px;
}
@mixin Text-MRegular-14-on-18 {
    font-family: Inter;
    font-size: 14px;
    line-height: 1.29;
}
@mixin Text-MRegular-14-on-16 {
    font-family: Inter;
    font-size: 14px;
    line-height: 1.14;
}
@mixin Text-MRegular {
    font-family: Inter;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.08px;
}
@mixin Text-MLight-14-on-16 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.14;
}
@mixin Text-SSemi-Bold {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
}
@mixin Text-SMedium {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
}
@mixin Text-SRegular {
    font-family: Inter;
    font-size: 12px;
    line-height: 1.33;
}
@mixin Text-SLight {
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.33;
}
@mixin SF-Symbols10-on-12 {
    font-family: SFPro;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.2;
}
@mixin Text-VSRegular {
    font-family: Inter;
    font-size: 10px;
    line-height: 1.4;
}

@font-face {
    font-family: Inter;
    src: local(Inter-Light), url(./assets/fonts/Inter-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-Italic), url(./assets/fonts/Inter-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-Regular), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-Medium), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-SemiBold), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

:root {
    --neutral-10: #fff;
    --variant-border: #7b61ff;
    --neutral-60: #9e9e9e;
    --neutral-20: #f5f5f5;
    --neutral-80: #616161;
    --neutral-70: #757575;
    --neutral-30: #ededed;
    --neutral-40: #e0e0e0;
    --neutral-90: #424242;
    --neutral-50: #c2c2c2;
    --neutral-100: #0a0a0a;
    --neutral-10-at-80: #fffc;
    --neutral-90-at-30: #4242424c;
    --neutral-10-at-94: #fffffff0;
    --neutral-10-at-50: #ffffff80;
    --neutral-80-at-60: #61616199;
    --neutral-70-at-16: #75757528;
    --neutral-100-at-4: #0000000a;
    --neutral-transparent: #fff0;
    --primary-default: #6aad00;
    --primary-hover: #66a00b;
    --primary-focus: #88c82333;
    --primary-active: #57870b;
    --primary-light: #9ddf46;
    --primary-dark: #367d00;
    --primary-border: #bce778;
    --primary-very-light: #c9e7a1;
    --primary-visited: #362ac0;
    --primary-surface: #fafff2;
    --secondary-default: #6c757d;
    --secondary-light: #9aa4ac;
    --secondary-border: #cbd1d7;
    --secondary-surface: #f0f4f8;
    --secondary-hover: #535a61;
    --secondary-active: #414951;
    --secondary-dark: #414951;
    --danger-default: #e00000;
    --danger-surface: #ffecec;
    --danger-border: #ffd7d7;
    --secondary-focus: #6c757d33;
    --danger-hover: #a20000;
    --danger-active: #630000;
    --danger-focus: #e0000033;
    --warning-surface: #fff2e7;
    --warning-border: #ffca99;
    --warning-hover: #d16603;
    --warning-active: #653508;
    --success-default: #008243;
    --success-surface: #ebf5f0;
    --success-border: #b3dac7;
    --success-hover: #006836;
    --success-active: #005029;
    --success-focus: #00824333;
    --info-default: #0b5cd7;
    --info-surface: #ecf2fc;
    --info-border: #b6cff3;
    --info-hover: #044dba;
    --info-active: #043c8f;
    --table-light-edit-row-background-row-hover: #fff;
    --table-light-normal-cell-background-active: #1a7abb1e;
    --table-light-normal-cell-background-hover: #1a7abb0f;
    --table-dark-normal-cell-background-active: #1a7abb19;
    --systemic-logo-green-text: #44b549;
    --state-colours-info: #5a90e2;
    --state-colours-low: #fdd64d;
    --state-colours-warning: #ff9c54;
    --state-colours-high: #ff601c;
    --warning-default: #ea780e;
    --state-colours-severe: #eb294c;
    --state-colours-critical: #e01a91;
    --state-colours-ok: #58ca93;
    --notifications-badge-red: #eb4d3d;
    --i-os-keyboard-icons: #50555c;
    --i-os-keyboard-background: #d1d3d9;
    --i-os-keyboard-dark-keys-background: #abb0bc;
    --i-os-light-mode-sheet-background: #f2f2f6;
    --i-os-light-mode-panel-background: #f5f5f5f0;
    --i-os-light-mode-perentie-panel-background: #f6fceff0;
    --i-os-system-colours-green: #34c759;
    --i-os-system-colours-blue: #007aff;
    --main-menu-dark-background: #242728;
    --main-menu-dark-item-background: #242728;
    --main-menu-dark-item-hover-background: #383d3f;
    --main-menu-dark-item-focused-border: #57656933;
    --main-menu-dark-item-active-background: #354145;
    --main-menu-dark-header-background: #191b1c;
    --top-bar-dark-background: #0c3357;
    --perentie-logo-head: #fdfdfd;
    --perentie-logo-text: #b6d44a;
    --perentie-logo-dark-green: #91b349;
}

login-page {
    /*height: 100vh;*/
    /*background-color: white;*/
}

.login-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 630px;
    min-width: 320px;
    height: auto;
    z-index: 2000;
    visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(-50%) translateY(-50%);
}

.login-container {
    background: white;
    min-height: 100%;
}

.login-show {
    visibility: visible;
}

.login-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background-color: black;
    transition: all 0.3s;
}

.login-show ~ .login-overlay {
    opacity: 0.4;
    visibility: visible;
}

.login-container {
    color: #fff;
    background: white;
    position: relative;
    border-radius: 3px;
    margin: 0 auto;
}

.login-container h3 {
    margin: 0;
    padding: 0.4em;
    text-align: center;
    font-size: 2.4em;
    font-weight: 300;
    opacity: 0.8;
    background: rgba(0, 0, 0);
    border-radius: 3px 3px 0 0;
}

.login-container > div {
    padding: 15px 40px 30px;
    margin: 0;
    font-weight: 300;
    font-size: 1.15em;
}

.login-container .login-buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container .login-buttons > div {
    height: 44px;
}

.login-effect-1 .login-container {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.login-show.login-effect-1 .login-container {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
