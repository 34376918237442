@import "../../index.scss";

/* ManageRostersPage.module.scss */

.content {
  height: 100%;
  padding: 16px;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
}

.roster-summary {
  margin-bottom: 24px;
}

.rosterForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 40%;
}

.rosterFormField {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.rostersTable {
  border-collapse: collapse;

  thead {
    background-color: #f0f0f0;
  }

  th {
    padding: 12px 16px;
    font-weight: 600;
    color: #333;
    border-bottom: 2px solid #ddd;
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    // position cell contents at top of cell
    vertical-align: top;
  }

  tr:nth-child(even) {
    background-color: #fafafa;
  }
}

.rosterTable {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.rosterTable thead {
  background-color: #f0f0f0;
}

.rosterTable th {
  padding: 12px 16px;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #ddd;
}

.rosterTable td {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  width: calc(100% / 7);
  // position cell contents at top of cell
  vertical-align: top;
}

.rosterTable tr:nth-child(even) {
  background-color: #fafafa;
}

.rosterTable tr:last-child td {
  border-bottom: none;
}

.button-group {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
}

footer .Button {
  min-width: 80px;
}

.editRosterSection {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.editRosterSection h3 {
  margin-top: 0;
  color: #333;
}

.editRosterSection dl {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.editRosterSection dl dt {
  flex: 1 1 calc(33.33% - 12px);
  font-size: 0.95rem;
  color: #555;
}

.editRosterSection dl dt input {
  margin-right: 8px;
}

.noChain {
  color: #777;
  font-style: italic;
  text-align: center;
  margin-top: 8px;
}

.NotifyChainDetails {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 6px;
  width: 50%;
  min-width: 230px;
  background-color: rgba(230, 234, 238, 0.75);

  span {
    color: #595959;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    margin: 4px 0 4px 0;
    padding-left: 2px;
  }
  span:first-of-type {
    color: #000;
    margin-bottom: 6px;
    padding-left: 0;
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
  }
  dt {
    flex-basis: 70%;
    padding: 4px;
    //background: azure;
    border-top: 1px solid #d9d9d9;
    text-align: right;
    color: #595959;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
  }
  dd {
    flex-basis: 20%;
    flex-grow: 1;
    margin: 0;
    padding: 4px;
    display: flex;
    align-content: center;
    /*    border-bottom: 1px solid #333;*/
    border-top: 1px solid #d9d9d9;

    font-size: 13px;
    color: #000;
    font-weight: normal;
    line-height: 1.54;
  }

  dd:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dd:nth-of-type(even) {
    background-color: #fff;
  }

  dt:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dt:nth-of-type(even) {
    background-color: #fff;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}