.container {
    padding: 16px;
    overflow: auto;
    height: 100%;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: darkgray;
    margin: 38px 0;
  }

  .configSection {
    margin-top: 38px;
    width: 100%;
    height: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .uploadSection {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;

    h3 {
      margin: unset;
    }

    input {
      margin-right: 8px;
    }
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  .button {
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      opacity: 0.9;
    }
  }

  .editButton {
    @extend .button;
    background-color: #0066cc;
  }

  .downloadButton {
    @extend .button;
    background-color: #6aad00;
    text-decoration: none;
  }

  .saveButton {
    @extend .button;
    background-color: #28a745;
  }

  .cancelButton {
    @extend .button;
    background-color: #6c757d;
  }

  .editorContainer {
    display: flex;
    gap: 16px;
    height: 100%;
  }

  .editor {
    flex: 1;
    height: 100%;
    font-family: monospace;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    resize: vertical;
    overflow: auto;
  }

  .preview {
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
