@import "../../index.scss";

.fallback-page {
    @include page;

    align-items: center;
    justify-content: center;

    gap: 32px;

    code {
        font-size: 20px;
        color: var(--neutral-90);
    }
}
