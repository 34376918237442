.modal {
    height: 100%;
    background-color: white;
    margin: auto;
    border-radius: 10px;

    .modal-body {
        height: 100%;

        .header {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 12px;
            padding-right: 12px;
    
            button {
                background: none;
                border: none;
                cursor: pointer;
    
                &:hover {
                    color: #6AAD00;
                }
            }
        }

        .content {
            height: calc(100% - 132px);
            padding: 16px;
        }

        .footer {
            padding-right: 16px;
        }
    }
}
