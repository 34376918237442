.top-bar {
    height: 64px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    background-image: linear-gradient(to bottom, #517010 0%, #6c990c 100%);
}
