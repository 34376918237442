.group-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4;
    border: 1px solid #6AAD00;
    border-radius: 6px;
}

.perentie-button {
    background-color: #efefef !important;
    height: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  
    span {
      color: #000 !important;
      font-size: 12px !important;
    }
  
    &:hover {
      background-color: #6AAD00 !important;

      span {
        color: #fff !important;
        font-size: 12px !important;
    }
    }

    &:first-child {
        border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
        border-radius: 0px 5px 5px 0px;
    }
}

.selected {
    background-color: #6AAD00 !important;

    span {
        font-weight: bold;
        color: #fff !important;
        font-size: 12px !important;
    }
}