@import "../../../index.scss";
@import "../Input.module.scss";

$sizes: (
    "s": (
        height: 24px,
        padding: 4px 8px,
    ),
    "sm": (
        height: 32px,
        padding: 8px,
    ),
);

$states: (
    "default": (
        border: solid 1px $neutral-50,
        background-color: $neutral-10,
        box-shadow: inset 1px 2px 2px 0 #0000001e,
    ),
    "hover": (
        border: solid 1px $primary-hover,
        background-color: $neutral-10,
        box-shadow: inset 1px 2px 2px 0 #0000001e,
    ),
    "focused": (
        border: solid 1px $primary-hover,
        background-color: $neutral-10,
        box-shadow: inset 1px 2px 2px 0 #0000001e,
    ),
    "disabled": (
        border: solid 1px $neutral-40,
        background-color: $neutral-20,
        box-shadow: none,
    ),
    "active": (
        border: solid 1px $primary-hover,
        background-color: $neutral-10,
        box-shadow: inset 1px 2px 2px 0 #0000001e,
    ),
);

.container {
    @include input;

    @each $size, $size-style in $sizes {
        @each $state, $state-style in $states {
            .#{$state}-#{$size} {
                display: flex;
                position: relative;
                border-radius: 4px;
                gap: 4px;
                border: map-get($map: $state-style, $key: border);
                background-color: map-get($map: $state-style, $key: background-color);
                box-shadow: map-get($map: $state-style, $key: box-shadow);
                flex-grow: 1;

                input {
                    height: map-get($map: $size-style, $key: height);
                    flex-grow: 1;
                    border-radius: inherit;
                    border: none;
                    background-color: transparent;
                    padding: map-get($map: $size-style, $key: padding);

                    font-family: Inter;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--neutral-90);

                    align-self: center;

                    &:focus {
                        outline: none;
                    }
                }

                .action {
                    height: inherit;
                    position: absolute;
                    right: 12px;
                    align-self: center;
                    align-items: center;
                    display: flex;
                    cursor: pointer;
                }
            }
        }
    }
}
