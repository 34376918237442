@import "../../../index.scss";
@import "../Input.module.scss";

.container {
    @include input;
    textarea {
        padding: 6px 4px 4px 12px;
        border-radius: 4px;
        box-shadow: inset 1px 2px 2px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px var(--neutral-50);
        background-color: var(--neutral-10);
        flex-grow: 1;

        resize: vertical;
        min-height: 100px;

        text-align: left;
        color: $neutral-60;
        @include Text-MRegular;

        &:hover {
            border: solid 1px var(--primary-hover);
            background-color: var(--neutral-10);
        }

        &:focus {
            outline: none;
            border: solid 1px var(--primary-hover);
            background-color: var(--neutral-10);
            color: $neutral-90;
        }

        &:disabled {
            border: solid 1px var(--neutral-40);
            background-color: var(--neutral-20);
        }
    }
}
