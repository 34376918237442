.screen-identity-bar {
    min-height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    box-shadow: inset 0 -1px 0 0 var(--neutral-40);
    background-color: var(--neutral-20);
    overflow: hidden;

    .title {
        flex-grow: 0;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.18px;
        text-align: left;
        color: var(--neutral-90);
    }
}
