@import "../../../index.scss";

.content {
    display: flex;
    flex-direction: column; 
    padding: 15px;
    gap: 15px;
    height: 100%; 
    overflow: scroll;
}

.header {
    display: flex; 
    justify-content: space-between; 
    flex: 1;
}

.actions {
    display: flex; 
    justify-content: flex-end; 
    gap: 10px;
}