@import "../../index.scss";

.copy-id-icon {
    width: 15.8px;
    height: 18.3px;
    background-color: var(--primary-default);
}

.manage-agents-page {
    @include page;

    .container {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: solid 1px var(--neutral-40);
        background-color: var(--neutral-10);
        margin: 16px;
    }

    .header {
        height: 40px;
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        padding: 8px 16px;
        background-color: var(--neutral-20);

        .text {
            height: 20px;
            flex-grow: 1;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.08px;
            text-align: left;
            color: var(--neutral-90);
        }
    }

    .agents {
        gap: 16px;
        padding: 16px;
    }
}
