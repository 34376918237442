@import "../../index.scss";
@import "../../components/Input/Input.module.scss";

.content {
  height: 100%;
  padding: 16px;
  overflow-y: auto;
}

.alertDetails {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 6px;
  //width: 50%;
  min-width: 230px;

  .header {
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
  }

  button {
    span {
      color: white !important;
      font-size: 12px;
      font-weight: normal;
    }
    background-color: #6AAD00 !important;
    height: 2px;
  }

  span {
    color: #595959;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    margin: 4px 0 4px 0;
    padding-left: 2px;
  }
  span:first-of-type {
    color: #000;
    margin-bottom: 6px;
    padding-left: 0;
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
  }
  dt {
    flex-basis: 30%;
    padding: 4px;
    //background: azure;
    border-top: 1px solid #d9d9d9;
    text-align: right;
    color: #595959;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
  }
  dd {
    flex-basis: 60%;
    flex-grow: 1;
    margin: 0;
    padding: 4px;
    display: flex;
    align-content: center;
    /*    border-bottom: 1px solid #333;*/
    border-top: 1px solid #d9d9d9;

    font-size: 13px;
    color: #000;
    font-weight: normal;
    line-height: 1.54;
  }

  dd:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dd:nth-of-type(even) {
    background-color: #fff;
  }

  dt:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dt:nth-of-type(even) {
    background-color: #fff;
  }
}

.count {
  color: white !important;
  padding: 2px 4px !important;
  border-radius: 25% !important;
}
.countActive{
  background-color: $info-default !important;
}
.countEnded{
  background-color: $neutral-60 !important;
}
.comment{
  max-width: 40vw;
  .description {
    max-width: 100%;
    overflow-wrap: break-word;
  }
  div:first-child {
    font-style: italic;
    color:#424242;
    margin-bottom: 8px;
  }
  div:nth-child(2){
    margin-left: 8px;
    margin-right: 8px;
  }
  div:last-child{
    height: 1px;
    background: #e0e0e0 !important;
    margin-top: 16px;
    margin-right: 8px;
  }
}

.action-event {
  border: 1px solid #6AAD00;
  border-radius: 6px;
  padding: 12px;
  max-height: 250px;
  overflow: hidden;

  .clickable {
    &:hover {
      color: #6AAD00 !important;
      cursor: pointer;
    }
  }

  .info {
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .info, .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
  }
}

.divider {
  border-top: 1px solid #808080;
  opacity: 0.5;
  height: 10px
}

.textarea {
    @include input;
    padding: 6px 4px 4px 12px;
    border-radius: 4px;
    box-shadow: inset 1px 2px 2px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px var(--neutral-50);
    background-color: var(--neutral-10);
    width: 90%;

    resize: none;
    min-height: 80px;

    text-align: left;
    color: $neutral-60;
    @include Text-MRegular;

    &:hover {
        border: solid 1px var(--primary-hover);
        background-color: var(--neutral-10);
    }

    &:focus {
        outline: none;
        border: solid 1px var(--primary-hover);
        background-color: var(--neutral-10);
        color: $neutral-90;
    }

    &:disabled {
        border: solid 1px var(--neutral-40);
        background-color: var(--neutral-20);
    }
}
.sm {
  min-height: 30px;
}

.perentie-button {
  background-color: #6AAD00 !important;
  border-radius: 6px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #fff !important;
    font-size: 12px !important;
  }

  &:hover {
    background-color: #5e9701 !important;
  }

  &:active {
    background-color: #6AAD00 !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;

    &:hover {
      background-color: #6AAD00 !important;
    }
  }
}

.perentie-button-ghost {
  background-color: #fff !important;
  border-radius: 6px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px !important;
  }

  &:hover {
    color: #6AAD00 !important;
  }
}

.action {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-radius: 6px;
  color: #6AAD00;

  &:hover {
    background-color: #6AAD00;
    color: #fff;
    cursor: pointer;
  }

  &:active {
    background-color: #fff;
    color: #6AAD00;
  }
}

.time-increment {
  display: flex;
  flex-direction: row;
  gap: 16px;


  button {
    background: none;
    border: none;
    cursor: pointer;
    color: #6AAD00;
  }
}

.alert-info {
  border: 1px solid #ececec;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:first-child {
    border-radius: 6px 6px 0px 0px;

    .alert-info-key {
      border-radius: 5px 0px 0px 0px;
    }

    .alert-info-val {
      border-radius: 0px 5px 0px 0px;
    }
  }

  &:not(:first-child) {
    border-top: none;
  }

  &:last-child {
    border-radius: 0px 0px 6px 6px;

    .alert-info-key {
      border-radius: 0px 0px 0px 5px;
    }

    .alert-info-val {
      border-radius: 0px 0px 5px 0px;
    }
  }

  .alert-info-key {
    width: 35%;
    min-width: 35%;
    background-color: rgba(106, 173, 0, 0.1);
    padding: 2px 12px;

    span {
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .alert-info-val {
    width: 65%;
    background-color: rgb(255, 255, 255);
    padding: 2px 12px;
    display: flex;
    align-items: center;

    span {
      font-weight: normal;
    }
    pre {
      font-size: 13px;
      font-family: 'Inter', SansSerif;
      overflow: auto;
      margin: unset;
      text-wrap: wrap;
    }
  }
}

.alert-info-val-clickable {
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #6AAD00 !important;
    cursor: pointer;
  }

  &:active {

      color: #000;

  }
}
