@import "../../index";

.base{
  margin: 0 !important;
  font-family: inherit;
  font-size: inherit !important;
  font-weight: inherit !important;
  padding: 0 !important;
}

.UNKNOWN{
  color: $neutral-90 !important;
}
.OK{
  color: $state-colours-ok !important;
}
.INFO{
  color: $state-colours-info !important;
}
.WARNING{
  color: $state-colours-warning !important;
}
.SEVERE{
  color: $state-colours-severe !important;
}
.CRITICAL{
  color: $state-colours-critical !important;
}
