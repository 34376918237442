@import "../../index.scss";
@import "../../components/Input/Input.module.scss";

.content {
  display: flex;
  flex:1;
  padding: 16px;
  overflow-y: auto;
}

.alertCard{
  padding: 8px 16px;
  border: 1px solid var(--neutral-90);
  border-radius: 6px;
  height: fit-content;
  h3, h4 {
    margin: unset;
  }
  h4 {
    margin-top: 8px;
  }
}

.cardGrid{
  display:grid;
  width: 95%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
}

.textarea {
  @include input;
  padding: 6px 4px 4px 12px;
  border-radius: 4px;
  box-shadow: inset 1px 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px var(--neutral-50);
  background-color: var(--neutral-10);
  width: 90%;

  resize: none;
  min-height: 80px;

  text-align: left;
  color: $neutral-60;
  @include Text-MRegular;

  &:hover {
    border: solid 1px var(--primary-hover);
    background-color: var(--neutral-10);
  }

  &:focus {
    outline: none;
    border: solid 1px var(--primary-hover);
    background-color: var(--neutral-10);
    color: $neutral-90;
  }

  &:disabled {
    border: solid 1px var(--neutral-40);
    background-color: var(--neutral-20);
  }
}
.sm {
  min-height: 30px;
}

.perentie-button {
  background-color: #6AAD00 !important;
  border-radius: 6px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #fff !important;
    font-size: 12px !important;
  }

  &:hover {
    background-color: #5e9701 !important;
  }

  &:active {
    background-color: #6AAD00 !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;

    &:hover {
      background-color: #6AAD00 !important;
    }
  }
}

.perentie-button-ghost {
  background-color: #fff !important;
  border-radius: 6px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px !important;
  }

  &:hover {
    color: #6AAD00 !important;
  }
}
