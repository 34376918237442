@import "../../index.scss";

.register-agents-page {
    @include page;

    .container {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: solid 1px var(--neutral-40);
        background-color: var(--neutral-10);
        margin: 16px;
    }
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 16px;
}
