@import "../../index.scss";

.content {
  display: flex;
  flex-direction: column;
  flex:1;
  //width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  border: 1px solid lime
}

.label {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.08px;
  height: 20px;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
  color: #0a0a0a;
}
