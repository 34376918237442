@import "../../index.scss";

.content {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: hidden;
}
p {
  margin: unset;
}

.chainTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;

  th, td {
    text-align: left;
  }

  th:nth-child(1), td:nth-child(1) {
    width: 40%; /* Adjust the percentage as needed */
  }

  th:nth-child(2), td:nth-child(2) {
    width: 40%; /* Adjust the percentage as needed */
  }

  th:nth-child(3), td:nth-child(3) {
    width: 10%; /* Adjust the percentage as needed */
  }

  th:nth-child(4), td:nth-child(4) {
    width: 10%; /* Adjust the percentage as needed */
  }

  th {
    background-color: #F0F7E5;
    font-weight: normal;
  }

  td {
    padding-top: 8px;
  }

  .onCall {
    color: $success-default;
  }

  .offCall {
    color: $danger-default;
  }
}

.compDetails {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 6px;
  //width: 50%;
  min-width: 230px;

  span {
    color: #595959;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    margin: 4px 0 4px 0;
    padding-left: 2px;
  }
  span:first-of-type {
    color: #000;
    margin-bottom: 6px;
    padding-left: 0;
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
  }
  dt {
    flex-basis: 30%;
    padding: 4px;
    //background: azure;
    border-top: 1px solid #d9d9d9;
    text-align: right;
    color: #595959;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
  }
  dd {
    flex-basis: 60%;
    flex-grow: 1;
    margin: 0;
    padding: 4px;
    display: flex;
    align-content: center;
    /*    border-bottom: 1px solid #333;*/
    border-top: 1px solid #d9d9d9;

    font-size: 13px;
    color: #000;
    font-weight: normal;
    line-height: 1.54;
  }

  dd:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dd:nth-of-type(even) {
    background-color: #fff;
  }

  dt:nth-of-type(odd) {
    background-color: #fbfafa;
  }
  dt:nth-of-type(even) {
    background-color: #fff;
  }
}

.component-info {
  border: 1px solid #ececec;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &:first-child {
    border-radius: 6px 6px 0px 0px;

    .component-info-key {
      border-radius: 5px 0px 0px 0px;
    }

    .component-info-val {
      border-radius: 0px 5px 0px 0px;
    }
  }

  &:not(:first-child) {
    border-top: none;
  }

  &:last-child {
    border-radius: 0px 0px 6px 6px;

    .component-info-key {
      border-radius: 0px 0px 0px 5px;
    }

    .component-info-val {
      border-radius: 0px 0px 5px 0px;
    }
  }

  .component-info-key {
    width: 35%;
    background-color: rgba(106, 173, 0, 0.1);
    padding: 2px 12px;

    span {
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
    }
  }

  .component-info-val {
    width: 65%;
    background-color: rgb(255, 255, 255);
    padding: 2px 12px;
    display: flex;
    align-items: center;

    span {
      font-weight: normal;
    }
  }
}
