.login-page {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        align-self: center;
        padding: 24px;
    }

    .login-form {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        input {
            border: none;
            border-bottom: solid 1px var(--neutral-50);
            background-color: var(--neutral-20);
            padding: 10px;
            font-size: 16px;
            color: var(--neutral-80);
        }
        input[type=password]{
            font-weight: normal;
            letter-spacing: inherit;
        }

        .login-button-group {
            display: flex;
            flex-direction: column;
            gap: 8px;

            button {
                width: 100%;
                height: 40px;
                border: none;
                border-radius: 4px;
                color: var(--neutral-100);
                font-size: 14px;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: var(--neutral-30);
                }

                &:focus {
                    outline: none;
                }

                &:active {
                    background-color: var(--neutral-40);
                }
            }
        }
    }
}
