@import "../../index.scss";

.container {
    @include page;
    .vertical-rule {
        width: 1px;
        background-color: var(--neutral-40);
    }

    .title {
        height: 20px;
        flex-grow: 0;
        text-align: left;
        color: $neutral-90;
        @include Text-MRegular;
    }

    pre {
        margin: 4px 0 0;
        padding: 6px 4px 4px 12px;
        border-radius: 4px;
        box-shadow: inset 1px 2px 2px 0 #0000001e;
        border: solid 1px var(--neutral-40);
        background-color: var(--neutral-20);
        flex-grow: 1;
    }

    .connection-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        object-fit: contain;
        border-radius: 4px;
        border: solid 1px var(--neutral-50);
        background-color: var(--neutral-10);

        .cell {
            align-self: stretch;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            box-shadow: 0 -0.5px 0 0 #00000040;
            background-color: var(--neutral-20);
        }
    }
}
