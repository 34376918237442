@import "../../index.scss";
.alertCard {
  padding: 16px;
  //border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  color: #333;
  height: fit-content;

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #e0e0e0;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-top: 8px;
    font-weight: 600;
  }

  td {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 8px;
    color: #555;
  }

  td:first-child {
    font-weight: 600;
    text-align: right;
  }

  .closeButton {
    background-color: #e0e0e0;
    border: none;
    border-radius: 4px;
    color: #333;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #d0d0d0;
    }
  }
}
.border-resolved{
  border: 1px solid #e0e0e0;
}
.border-info{
  border: 1px solid  $state-colours-info;
}
.border-warning{
  border: 1px solid  $state-colours-warning;
}
.border-severe{
  border: 1px solid  $state-colours-severe;
}
.border-critical{
  border: 1px solid  $state-colours-critical;
}
