@import "../../../index.scss";

.content {
  height: 100%;
  padding: 16px;
  overflow-y: auto;
}

.header {
  display: flex; 
  justify-content: space-between; 
  flex: 1;
}

.actions {
  display: flex; 
  justify-content: flex-end; 
  gap: 10px;
}