// SimpleNotifyChain.module.scss
.card {
    background: white;
    border-radius: 0.5rem;
  }
  
  .content {
    > div:not(:last-child) {
      border-bottom: 1px solid rgb(243, 244, 246);
    }
  }
  
  .emptyState {
    padding: 1rem;
    font-style: italic;
    color: rgb(107, 114, 128);
    font-size: 0.875rem;
  }
  
  .chainLink {
    padding: 1rem;
  }
  
  .levelHeader {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .levelText {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgb(55, 65, 81);
  }
  
  .userFlex {
    display: flex;
    flex-direction: column;
  }
  
  .username {
    font-size: 0.875rem;
    color: rgb(75, 85, 99);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .status {
    font-size: 0.75rem;
  }
  
  .onCall,
  .offCall {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-weight: 500;
    font-size: 0.75rem;
  }
  
  .onCall {
    background-color: rgb(240, 253, 244);
    color: rgb(22, 101, 52);
  }
  
  .offCall {
    background-color: rgb(254, 242, 242);
    color: rgb(153, 27, 27);
  }