@import "../../index.scss";

$states: (
    "connected": (
        "fill": $state-colours-ok,
    ),
    "disconnected": (
        "fill": $state-colours-severe,
    ),
);

.container {
    display: flex;
    min-width: max-content;
    @each $state, $state-style in $states {
        .icon-#{$state} {
            width: 49px;
            height: 16px;
            fill: map-get($map: $state-style, $key: fill);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px;
            border-radius: 32px;
            display: flex;
        }
    }
}
