.bottom-bar {
    height: 36px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 24px;
    box-shadow: inset 0 1px 0 0 var(--neutral-40);
    background-color: var(--neutral-20);
    font-size: 12px;
    color: var(--neutral-70);
    gap: 24px;

    .brand {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .copyright {
        color: var(--neutral-90);
    }

    .support {
        color: var(--primary-default);
    }

    .disclaimer {
        color: var(--neutral-70);
    }
}
